.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.outingsTableContainer > table {
  table-layout: fixed;
}

.outingsTableContainer > table > tbody > tr {
  cursor: pointer;
  height: 40px;
  line-height: 30px;
}

.outingsTableContainer > table > thead > tr {
  height: 40px;
  line-height: 30px;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions > * {
  margin-left: 15px;
}

.fixedColSmall {
  width: 120px;
}

.fixedColMed {
  width: 240px;
}

.fixedColLarge {
  width: 300px;
}

img, span {
  display: inline-block;
  vertical-align: middle;
}