.actions {
  grid-area: actions;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.header {
  width: 500px;
}

.form {
  width: 500px;
}

.error_message {
  color: #bf1650;
}
