.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.partsTableContainer > table {
  table-layout: fixed;
}

.partsTableContainer > table > tbody > tr {
  cursor: pointer;
  line-height: 26px;
}

.partsTableContainer > table > thead > tr {
  height: 40px;
  line-height: 30px;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.nearLimit {
  color: #ffea00;
  font-weight: bold;
}

.overLimit {
  color: #eb0013;
  font-weight: bold;
}

.fixedColSmall {
  width: 120px;
}

.fixedColMed {
  width: 180px;
}

.fixedColLarge {
  width: 350px;
}

.partsTableContainer > table > tbody > tr > td > button {
  margin-left: 2px;
  margin-right: 2px;
}
