.table {
  font-size: .90rem;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterIcon {
  padding: 5px;
}

.tableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rowsPerPage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableFooter > *,
.rowsPerPage > * {
  margin-left: 10px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
