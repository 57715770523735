.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.partConfigsTableContainer > table {
  table-layout: fixed;
}

.partConfigsTableContainer > table > tbody > tr {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
