body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * Bootstrap overrides
 */
.table.dark,
.table-striped.dark > tbody > tr:nth-of-type(2n+1) > * {
  color: #FFF;
}

.table-hover.dark > tbody > tr:hover > * {
  background-color: rgb(56, 56, 56);
  color: #FFF;
}

.form-label {
  margin-bottom: 3px;
}

main.dark .form-control:disabled,
main.dark .form-select:disabled {
  background-color: #BEBEBE;
}

.nav-tabs {
  --bs-nav-tabs-border-color: #000;
  --bs-nav-tabs-link-active-border-color: #000;
  --bs-nav-tabs-link-hover-border-color: #000;
}
.nav-tabs.dark {
  --bs-nav-tabs-border-color: #FFF;
  --bs-nav-tabs-link-active-border-color: #FFF;
  --bs-nav-tabs-link-hover-border-color: #FFF;
}
.nav-tabs .nav-link:not(.active) {
  color: #000;
}
.nav-tabs.dark .nav-link:not(.active) {
  color: #FFF;
}

.toast-container {
  position: fixed !important;
  padding: 15px;
}