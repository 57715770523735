.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  display: flex;
}

.actions {
  display: flex;
}

.viewTabsContainer {
  margin-top: 15px;
  width: 100%;
}

.viewTab {
  width: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.colContainer {
  display: flex;
  column-gap: 20px;
}

.form .colContainer > div {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.buildForm {
  display: flex;
  margin-bottom: 20px;
  margin-top: 30px;
}

.buildForm .colContainer > div {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.dark .fieldset > h3:first-child {
  background-color: #2C2C2C;
}

.fieldset {
  display: grid;
  gap: 5px;
  position: relative;
  border: 1px solid #2C2C2C;
  padding: 15px;
}

.dark .fieldset {
  border: 1px solid #FFF;
}

.removeButton {
  color:tomato;
}

.fieldset > h3:first-child {
  position: absolute;
  top: -12px;
  left: 10px;
  font-size: 1.1rem;
  background-color: #FFF;
  padding: 0 12px;
}

.inputCell {
  justify-self: stretch;
}

.labelCell,
.calcCell {
  place-self: center;
}

.labelCell {
  font-weight: bold;
}

.desc {
  grid-area: desc;
}

.expiringPart {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 10px;
}

/* Event */
.event {
  grid-template-columns: repeat(3, 1fr 2fr);
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    "event-label    event   session-label   session           run-label   run"
    "track-label    track   track           length-label      length      length"
    "car-label      car     car             driver-label      driver      driver"
    "date-label     date    date            engineer-label    engineer    engineer"
    "time-label     time    time            weather-label     weather     weather"
    "temp-label     temp    temp            track-temp-label  track-temp  track-temp";
  justify-items: center;
  align-items: center;
}
.customEvent {
  grid-template-columns: repeat(3, 1fr 2fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "event-label    event   session-label   session           run-label   run"
    "track-label    track   track           length-label      length      length"
    "car-label      car     car             driver-label      driver      driver"
    "date-label     date    date            engineer-label    engineer    engineer"
    "time-label     time    time            weather-label     weather     weather"
    "temp-label     temp    temp            track-temp-label  track-temp  track-temp";
  justify-items: center;
  align-items: center;
}
.eventLabel {
  grid-area: event-label;
}
.event {
  grid-area: event;
}
.sessionLabel {
  grid-area: session-label;
}
.session {
  grid-area: session;
}
.runLabel {
  grid-area: run-label;
}
.run {
  grid-area: run;
}
.trackLabel {
  grid-area: track-label;
}
.track {
  grid-area: track;
}
.lengthLabel {
  grid-area: length-label;
}
.length {
  grid-area: length;
}
.carLabel {
  grid-area: car-label;
}
.car {
  grid-area: car;
}
.driverLabel {
  grid-area: driver-label;
}
.driver {
  grid-area: driver;
}
.dateLabel {
  grid-area: date-label;
}
.date {
  grid-area: date;
}
.engineerLabel {
  grid-area: engineer-label;
}
.engineer {
  grid-area: engineer;
}
.timeLabel {
  grid-area: time-label;
}
.time {
  grid-area: time;
}
.weatherLabel {
  grid-area: weather-label;
}
.weather {
  grid-area: weather;
}
.tempLabel {
  grid-area: temp-label;
}
.temp {
  grid-area: temp;
}
.trackTempLabel {
  grid-area: track-temp-label;
}
.trackTemp {
  grid-area: track-temp;
}

/* Weights */
.weights {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    "weight-label weight-lf weight-lf   weight-rf   weight-rf"
    "weight-label weight-lr weight-lr   weight-rr   weight-rr"
    "total-label  total     right-label right-label right"
    "nose-label   nose      cross-label cross-label cross"
    "driver-label driver    fuel-label  fuel        fuel-weight";
}
.weightLabel {
  grid-area: weight-label;
}
.weightLf {
  grid-area: weight-lf;
}
.weightRf {
  grid-area: weight-rf;
}
.weightLr {
  grid-area: weight-lr;
}
.weightRr {
  grid-area: weight-rr;
}
.rightLabel {
  grid-area: right-label;
}
.crossLabel {
  grid-area: cross-label;
}

/* Heights */
.heights {
  grid-template-columns: repeat(2, 20% auto auto);
  grid-template-areas:
    "frame-heights-label frame-heights-lf frame-heights-rf fender-heights-label fender-heights-lf fender-heights-rf"
    "frame-heights-label frame-heights-lr frame-heights-rr fender-heights-label fender-heights-lr fender-heights-rr";
}
.fenderHeightsLabel {
  grid-area: fender-heights-label;
}
.frameHeightsLabel {
  grid-area: frame-heights-label;
}

/* Settings */
.settings {
  grid-template-columns: repeat(2, 20% auto auto);
  grid-template-rows: auto auto;
  grid-template-areas:
    "camber-label   camber-lf       camber-rf       toe-label       toe-lf          toe-rf"
    "camber-label   camber-lr       camber-rr       toe-label       toe-lr          toe-rr"
}
.camberLabel {
  grid-area: camber-label;
}
.toeLabel {
  grid-area: toe-label;
}

/* Tires */
.tires {
  grid-template-areas:
    "tire-type-label  tire-type       tire-measurements-imp"
    "set-label        set             set"
    "pressure-label   pressure-lf     pressure-rf"
    "pressure-label   pressure-lr     pressure-rr";
}
.tireTypeLabel {
  grid-area: tire-type-label;
}
.tireType {
  grid-area: tire-type;
  align-self: center;
}
.tireMeasurementsImp {
  grid-area: tire-measurements-imp;
}
.tireMeasurementsMetric {
  grid-area: tire-measurements-metric;
}
.setLabel {
  grid-area: set-label;
}
.set {
  grid-area: set;
}
.pressureLabel {
  grid-area: pressure-label;
}
.pressureLf {
  grid-area: pressure-lf;
}
.pressureRf {
  grid-area: pressure-rf;
}
.pressureLr {
  grid-area: pressure-lr;
}
.pressureRr {
  grid-area: pressure-rr;
}

/* Suspension */
.suspension {
  grid-template-areas:
    ".                        front-label           front-label           rear-label            rear-label"
    ".                        left-front-label      right-front-label     left-rear-label       right-rear-label"
    "main-spring-label        main-spring-lf        main-spring-rf        main-spring-lr        main-spring-rr"
    "perch-gap-label          perch-gap-lf          perch-gap-rf          perch-gap-lr          perch-gap-rr"
    "compression-clicks-label compression-clicks-lf compression-clicks-rf compression-clicks-lr compression-clicks-rr"
    "rebound-clicks-label     rebound-clicks-lf     rebound-clicks-rf     rebound-clicks-lr     rebound-clicks-rr"
    "camber-letter-label      camber-letter-lf      camber-letter-rf      .                     ."
    "sway-bar-hole-label      sway-bar-hole-lf      sway-bar-hole-rf      sway-bar-hole-lr      sway-bar-hole-rr";
}
.frontLabel {
  grid-area: front-label;
}
.rearLabel {
  grid-area: rear-label;
}
.leftFrontLabel {
  grid-area: left-front-label;
}
.rightFrontLabel {
  grid-area: right-front-label;
}
.leftRearLabel {
  grid-area: left-rear-label;
}
.rightRearLabel {
  grid-area: right-rear-label;
}
.mainSpringLabel {
  grid-area: main-spring-label;
}
.mainSpringLf {
  grid-area: main-spring-lf;
}
.mainSpringRf {
  grid-area: main-spring-rf;
}
.mainSpringLr {
  grid-area: main-spring-lr;
}
.mainSpringRr {
  grid-area: main-spring-rr;
}
.perchGapLabel {
  grid-area: perch-gap-label;
}
.perchGapLf {
  grid-area: perch-gap-lf;
}
.perchGapRf {
  grid-area: perch-gap-rf;
}
.perchGapLr {
  grid-area: perch-gap-lr;
}
.perchGapRr {
  grid-area: perch-gap-rr;
}
.compressionClicksLabel {
  grid-area: compression-clicks-label;
}
.compressionClicksLf {
  grid-area: compression-clicks-lf;
}
.compressionClicksRf {
  grid-area: compression-clicks-rf;
}
.compressionClicksLr {
  grid-area: compression-clicks-lr;
}
.compressionClicksRr {
  grid-area: compression-clicks-rr;
}
.reboundClicksLabel {
  grid-area: rebound-clicks-label;
}
.reboundClicksLf {
  grid-area: rebound-clicks-lf;
}
.reboundClicksRf {
  grid-area: rebound-clicks-rf;
}
.reboundClicksLr {
  grid-area: rebound-clicks-lr;
}
.reboundClicksRr {
  grid-area: rebound-clicks-rr;
}
.camberLetterLabel {
  grid-area: camber-letter-label;
}
.camberLetterLf {
  grid-area: camber-letter-lf;
}
.camberLetterRf {
  grid-area: camber-letter-rf;
}
.swayBarHoleLabel {
  grid-area: sway-bar-hole-label;
}
.swayBarHoleLf {
  grid-area: sway-bar-hole-lf;
}
.swayBarHoleRf {
  grid-area: sway-bar-hole-rf;
}
.swayBarHoleLr {
  grid-area: sway-bar-hole-lr;
}
.swayBarHoleRr {
  grid-area: sway-bar-hole-rr;
}

/* Aero */
.aero {
  grid-template-columns: 1fr 1fr 1fr 3fr;
  grid-template-areas:
    "splitter-label       splitter-label      splitter-label        wing-angle-label"
    "splitter-left-label  splitter-mid-label  splitter-right-label  wing-angle-label"
    "splitter-left        splitter-mid        splitter-right        wing-angle";
}
.splitterLabel {
  grid-area: splitter-label;
}
.wingAngleLabel {
  justify-self: center;
  grid-area: wing-angle-label;
}
.splitterLeftLabel {
  grid-area: splitter-left-label;
}
.splitterMidLabel {
  grid-area: splitter-mid-label;
}
.splitterRightLabel {
  grid-area: splitter-right-label;
}
.splitterLeft {
  grid-area: splitter-left;
}
.splitterMid {
  grid-area: splitter-mid;
}
.splitterRight {
  grid-area: splitter-right;
}
.wingAngle {
  grid-area: wing-angle;
}

.actions {
  grid-area: actions;
  position: sticky;
  float: right;
  top: 35px;
  gap: 15px;
  z-index: 1;
  background-color: #212529;
  padding: 15px;
  border-radius: 5px;
}

.createActions {
  grid-template-columns: 1fr 1fr;
}

/* Details */
.details {
  margin-top: 20px;
  grid-template-columns: 1fr 5fr;
  column-gap: 15px !important;
  grid-template-areas:
    "laps other-details";
}
.outingLaps {
  grid-area: laps;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  grid-template-areas:
    "laps-label laps-label  lap-count-label lap-count"
    "laps       laps        laps            laps";
}
.laps {
  grid-area: laps;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-auto-rows: 35px;
  place-items: center;
}
.lapsLabel {
  grid-area: laps-label;
}
.lapCountLabel {
  grid-area: lap-count-label;
}
.lapCount {
  grid-area: lap-count;
}
.otherDetails {
  grid-area: other-details;
  align-self: start;
  display: grid;
  gap: 5px;
  grid-template-areas:
    /* Big boi comin' thru choo-choo */
    "changes-label              changes-label               changes-label         changes-label         changes-label         changes-label         changes-label         changes-label         changes-label         changes-label"
    "changes                    changes                     changes               changes               changes               changes               changes               changes               changes               changes"
    "driver-comments-label      driver-comments-label       driver-comments-label driver-comments-label driver-comments-label driver-comments-label driver-comments-label driver-comments-label driver-comments-label driver-comments-label"
    "driver-comments            driver-comments             driver-comments       driver-comments       driver-comments       driver-comments       driver-comments       driver-comments       driver-comments       driver-comments"
    "graph                      graph                       graph                 graph                 graph                 graph                 graph                 graph                 graph                 graph"
    "max-coolant-temp-label     max-coolant-temp-label      max-coolant-temp      fuel-added-label      fuel-added-label      fuel-added-label      fuel-added            tire-pressure-label   tire-pressure-lf      tire-pressure-rf"
    "max-engine-oil-temp-label  max-engine-oil-temp-label   max-engine-oil-temp   fuel-burn-label       fuel-burn-label       fuel-burn-label       fuel-burn             tire-pressure-label   tire-pressure-lr      tire-pressure-rr"
    "max-trans-fluid-temp-label max-trans-fluid-temp-label  max-trans-fluid-temp  fuel-mileage-label    fuel-mileage-label    fuel-mileage-label    fuel-mileage          rotor-temp-label      rotor-temp-lf         rotor-temp-rf"
    "max-diff-oil-temp-label    max-diff-oil-temp-label     max-diff-oil-temp     fuel-mileage-label    fuel-mileage-label    fuel-mileage-label    fuel-mileage          rotor-temp-label      rotor-temp-lr         rotor-temp-rr"
    "tire-temp-label            tire-temp-lf-outer          tire-temp-lf-middle   tire-temp-lf-inner    tire-temp-rf-inner    tire-temp-rf-middle   tire-temp-rf-outer    caliper-temp-label    caliper-temp-lf       caliper-temp-rf"
    "tire-temp-layout-label     tire-temp-lr-outer          tire-temp-lr-middle   tire-temp-lr-inner    tire-temp-rr-inner    tire-temp-rr-middle   tire-temp-rr-outer    caliper-temp-label    caliper-temp-lr       caliper-temp-rr";
}
.changesLabel {
  justify-self: start;
  grid-area: changes-label;
}
.changes {
  grid-area: changes;
}
.driverCommentsLabel {
  justify-self: start;
  grid-area: driver-comments-label;
}
.driverComments {
  grid-area: driver-comments;
}
.graph {
  grid-area: graph;
}
.maxCoolantTempLabel {
  grid-area: max-coolant-temp-label;
}
.maxCoolantTemp {
  grid-area: max-coolant-temp;
}
.maxEngineOilTempLabel {
  grid-area: max-engine-oil-temp-label;
}
.maxEngineOilTemp {
  grid-area: max-engine-oil-temp;
}
.maxTransFluidTempLabel {
  grid-area: max-trans-fluid-temp-label;
}
.maxTransFluidTemp {
  grid-area: max-trans-fluid-temp;
}
.maxDiffOilTempLabel {
  grid-area: max-diff-oil-temp-label;
}
.maxDiffOilTemp {
  grid-area: max-diff-oil-temp;
}
.tireTempLabel {
  grid-area: tire-temp-label;
}
.tireTempLayoutLabel {
  grid-area: tire-temp-layout-label;
}
.tireTempLfOuterLabel {
  grid-area: tire-temp-lf-outer;
}
.tireTempLfMiddleLabel {
  grid-area: tire-temp-lf-middle;
}
.tireTempLfInnerLabel {
  grid-area: tire-temp-lf-inner;
}
.tireTempRfOuterLabel {
  grid-area: tire-temp-rf-outer;
}
.tireTempRfMiddleLabel {
  grid-area: tire-temp-rf-middle;
}
.tireTempRfInnerLabel {
  grid-area: tire-temp-rf-inner;
}
.tireTempLrOuterLabel {
  grid-area: tire-temp-lr-outer;
}
.tireTempLrMiddleLabel {
  grid-area: tire-temp-lr-middle;
}
.tireTempLrInnerLabel {
  grid-area: tire-temp-lr-inner;
}
.tireTempRrOuterLabel {
  grid-area: tire-temp-rr-outer;
}
.tireTempRrMiddleLabel {
  grid-area: tire-temp-rr-middle;
}
.tireTempRrInnerLabel {
  grid-area: tire-temp-rr-inner;
}
.fuelAddedLabel {
  grid-area: fuel-added-label;
}
.fuelAdded {
  grid-area: fuel-added;
}
.fuelBurnLabel {
  grid-area: fuel-burn-label;
}
.fuelBurn {
  grid-area: fuel-burn;
}
.fuelMileageLabel {
  grid-area: fuel-mileage-label;
}
.fuelMileage {
  grid-area: fuel-mileage;
}
.tirePressureLabel {
  grid-area: tire-pressure-label;
}
.tirePressureLf {
  grid-area: tire-pressure-lf;
}
.tirePressureRf {
  grid-area: tire-pressure-rf;
}
.tirePressureLr {
  grid-area: tire-pressure-lr;
}
.tirePressureRr {
  grid-area: tire-pressure-rr;
}
.rotorTempLabel {
  grid-area: rotor-temp-label;
}
.rotorTempLf {
  grid-area: rotor-temp-lf;
}
.rotorTempRf {
  grid-area: rotor-temp-rf;
}
.rotorTempLr {
  grid-area: rotor-temp-lr;
}
.rotorTempRr {
  grid-area: rotor-temp-rr;
}
.caliperTempLabel {
  grid-area: caliper-temp-label;
}
.caliperTempLf {
  grid-area: caliper-temp-lf;
}
.caliperTempRf {
  grid-area: caliper-temp-rf;
}
.caliperTempLr {
  grid-area: caliper-temp-lr;
}
.caliperTempRr {
  grid-area: caliper-temp-rr;
}

/* Body */
.body {
  grid-template-areas:
    "chassis-label              chassis"
    "fuel-cell-bladder-label fuel-cell-bladder"
    "fire-bottle-label          fire-bottle"
    "seat-label                 seat";
  justify-items: center;
  align-items: center;
}
.chassisLabel {
  grid-area: chassis-label;
}
.fuelCellBladderLabel {
  grid-area: fuel-cell-bladder-label;
}
.fireBottleLabel {
  grid-area: fire-bottle-label;
}
.fireBottleExpDateLabel {
  grid-area: fire-bottle-exp-date-label;
}
.seatLabel {
  grid-area: seat-label;
}

/* Powertrain */
.powertrain {
  grid-template-areas:
    "engine-label                   engine"
    "engine-oil-filter-label        engine-oil-filter"
    "engine-coolant-label           engine-coolant"
    "spark-plugs-label              spark-plugs"
    "air-filter-label               air-filter"
    "lambda-sensor-label            lambda-sensor";
  justify-items: center;
  align-items: center;
}
.engineLabel {
  grid-area: engine-label;
}
.engineOilFilterLabel {
  grid-area: engine-oil-filter-label;
}
.engineCoolantlabel {
  grid-area: engine-coolant-label;
}
.sparkPlugsLabel {
  grid-area: spark-plugs-label;
}
.airFilterLabel {
  grid-area: air-filter-label;
}
.lambdaSensorLabel {
  grid-area: lambda-sensor-label;
}
.radiatorLabel {
  grid-area: radiator-label;
}

/* Driveline */
.driveline {
  grid-template-areas:
    "transmission-label                    transmission"
    "transmission-service-label            transmission-service"
    "transmission-oil-label                transmission-oil"
    "differential-oil-filter-clean-label   differential-oil-filter-clean"
    "propshaft-ujoint-label                propshaft-ujoint"
    "axle-lr-label                         axel-lr-cell"
    "axle-rr-label                         axel-rr-cell";
  justify-items: center;
  align-items: center;
}
.transmissionLabel {
  grid-area: transmission-label;
}
.transmissionServiceLabel {
  grid-area: transmission-service-label;
}
.transmissionOilLabel {
  grid-area: transmission-oil-label;
}
.differentialOilFilterCleanLabel {
  grid-area: differential-oil-filter-clean-label;
}
.propshaftUJointLabel {
  grid-area: propshaft-ujoint-label;
}
.driveshaftLabel {
  grid-area: driveshaft-label;
}
.axleLRLabel {
  grid-area: axle-lr-label;
}
.axleRRLabel {
  grid-area: axle-rr-label;
}

/* Electronics */
.electronics {
  grid-template-areas:
    "ms6-label           ms6"
    "pbx90-label         pbx90"
    "ddu11-dash-label    ddu11-dash"
    "lte-telemetry-label lte-telemetry"
    "abs-label           abs"
    "can-panel-label    can-panel"
    "mm510-label         mm510";
  justify-items: center;
  align-items: center;
}
.ms6Label {
  grid-area: ms6-label;
}
.pbx90Label {
  grid-area: pbx90-label;
}
.ddu11DashLabel {
  grid-area: ddu11-dash-label;
}
.lteTelemetryLabel {
  grid-area: lte-telemetry-label;
}
.absLabel {
  grid-area: abs-label;
}
.canPanelLabel {
  grid-area: can-panel-label;
}
.mm510Label {
  grid-area: mm510-label;
}

/* Brakes */
.brakes {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "calipers-front-label     calipers-lf         calipers-rf"
    "calipers-rear-label      calipers-lr         calipers-rr"
    "brake-fluid-label        brake-fluid         brake-fluid"
    "brake-pads-front-label   brake-pads-front    brake-pads-front"
    "brake-pads-rear-label    brake-pads-rear     brake-pads-rear"
    "brake-rotors-front-label brake-rotors-front  brake-rotors-front"
    "brake-rotors-rear-label  brake-rotors-rear   brake-rotors-rear";
  justify-items: center;
  align-items: center;
}
.calipersFrontLabel {
  grid-area: calipers-front-label;
}
.calipersRearLabel {
  grid-area: calipers-rear-label;
}
.calipersLF {
  grid-area: calipers-lf;
}
.calipersRF {
  grid-area: calipers-rf;
}
.calipersLR {
  grid-area: calipers-lr;
}
.calipersRR {
  grid-area: calipers-rr;
}
.brakeFluidLabel {
  grid-area: brake-fluid-label;
}
.brakeFluid {
  grid-area: brake-fluid;
}
.brakePadsFrontLabel {
  grid-area: brake-pads-front-label;
}
.brakePadsFront {
  grid-area: brake-pads-front;
}
.brakePadsRearLabel {
  grid-area: brake-pads-rear-label;
}
.brakePadsRear {
  grid-area: brake-pads-rear;
}
.brakeRotorsFrontLabel {
  grid-area: brake-rotors-front-label;
}
.brakeRotorsFront {
  grid-area: brake-rotors-front;
}
.brakeRotorsRearLabel {
  grid-area: brake-rotors-rear-label;
}
.brakeRotorsRear {
  grid-area: brake-rotors-rear;
}

/* Suspension */
.buildSuspension {
  grid-template-areas:
    "shock-front-label             shock-lf             shock-rf"
    "shock-rear-label              shock-lr             shock-rr";
  justify-items: center;
  align-items: center;
}
.shockFrontLabel {
  grid-area: shock-front-label;
}
.shockRearLabel {
  grid-area: shock-rear-label;
}

.tooltip-inner {
  max-width: none; 
}