main {
  background-color: #FFF;
  color: #2C2C2C;
}

main.dark {
  background-color: #2C2C2C;
  color: #FFF;
}

.content {
  padding: 1rem;
  min-height: calc(100vh - 30px);
}