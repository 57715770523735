.selectorContainer {
  position: relative;
  margin-top: 0.5rem;
  border-style: solid;
  border-width: 2px;
  padding: 1rem;
}

.selectorHeading {
  position: absolute;
  top: -0.9rem;
  padding: 0 1rem;
  display: inline;
  font-size: 1.2rem;
  background-color: white;
}

.selection {
  width: 150px;
  height: 150px;
  border-radius: 0 !important;
}

.selection:hover > img {
  filter: brightness(75%);
}
